export const initialValues={
    username:'',
    email:'',
    first_name:'',
    last_name:'',
    user_type:'',
    password:'',
    date_of_birth:'',
    grade_level:'',
    phone_number:'',
    address:'',
    department:'',
    qualification:'',
    parent_id:'',
    course_id :'',
    fee_deposit:'',
    course_amount:'',
    course_start_date:'',
    course_end_date:'',
    course_status:'',
}
